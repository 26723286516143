import { styled } from '@mui/system'

const BottomAreaWrapper = styled('div')`
	min-height: 68px;
	background-color: ${({ theme }) => theme.palette.footer.bgBottom};
	display: flex;
	align-items: center;
	border-top: 1px solid ${({ theme }) => theme.palette.footer.border};
	justify-content: center;
	text-align: center;
	padding: 0 25px;
	a {
		color: ${({ theme }) => theme.palette.footer.color};
		text-decoration-color: ${({ theme }) => theme.palette.footer.color};
	}
	.MuiTypography-subtitle1,
	p {
		font-family: ${({ theme }) => theme.typography.body1.fontFamily};
		font-size: ${({ theme }) => theme.typography.body1.fontSize};
		text-transform: initial;
	}
	${({ theme }) => theme.breakpoints.up('md')} {
		padding: 0 75px;
		justify-content: flex-start;
	}
`

export { BottomAreaWrapper }
