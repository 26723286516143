/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const MapPinIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path fill="none" d="M2.7 2.7h18.6v18.6H2.7z" />
		<path d="M12 2.8C8.4 2.7 5.4 5.4 5.3 9c0 3.5 3.6 9 5.5 11.6.6.8 1.8.8 2.4 0 1.9-2.6 5.5-8 5.5-11.6-.1-3.6-3.1-6.3-6.7-6.2zm0 8.9c-1.2 0-2.2-1-2.2-2.2 0-1.2 1-2.2 2.2-2.2s2.2 1 2.2 2.2-1 2.2-2.2 2.2z" />
	</svg>
))
MapPinIconSvg.displayName = 'MapPinIconSvg'

const MapPinIcon = forwardRef((props, ref) => <Icon component={MapPinIconSvg} ref={ref} {...props} />)
MapPinIcon.displayName = 'MapPinIcon'

MapPinIcon.defaultProps = {
	...Icon.defaultProps,
}
MapPinIcon.propTypes = {
	...Icon.propTypes,
}

export default MapPinIcon
export { MapPinIconSvg }
